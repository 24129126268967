import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled((props) => (
  <img src="/images/icons/processing.png" alt="loading" {...props} />
))`
  animation: ${spin} 1s infinite linear;
`;

export const CenterSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;

export default Spinner;
