/*
 *
 * We need to roll this back for the time being because in the
 * interrim of switching on the new home page, we still need to
 * point to / instead of /home
 *
 * The code is intentionally commented out below so that we can put it back
 * once we switch this on
 */

import React, { useCallback, useEffect } from "react";
import { Entry } from "contentful";
import { GetServerSidePropsResult, GetServerSidePropsContext } from "next";
import { contentful, CONTENT_TYPES } from "../lib/contentful";
import { Page } from "components/Contentful/Page";
import { IPage } from "components/Contentful/Page/types";
import { useRouter } from "next/router";
import { useAuth0 } from "@auth0/auth0-react";
import { usePermissions } from "lib/features";
import { getAccessToken } from "lib/auth";
import { CenterSpinner } from "components/atoms/Spinner";

type Page = { page: Entry<IPage>; preview: boolean };

function Home({ page, preview }: Page): JSX.Element {
  const { isAuthenticated } = useAuth0();
  const router = useRouter();

  /**
   * Handle the case where the user is not authenticated but exists
   * Route them to the home page
   */
  const handlePotentialUser = useCallback(async (): Promise<void> => {
    const accessToken = getAccessToken(); // this can be a inactive token but shows that a user has logged in prior
    if (isAuthenticated || !!accessToken) {
      router.push("/home"); // this will seamlessly log the user in
    }
  }, [isAuthenticated, router]);

  useEffect(() => {
    handlePotentialUser();
  }, [handlePotentialUser]);

  return !isAuthenticated ? (
    <Page page={page} preview={preview} />
  ) : (
    <CenterSpinner />
  );
}
export default Home;

export async function getServerSideProps(
  context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<Page>> {
  const { preview } = context;

  const pages = await contentful(preview).getEntries({
    content_type: CONTENT_TYPES.PAGE,
    "fields.url": `/home`,
    include: 3,
  });

  const page = pages.items[0];

  return {
    props: {
      page: JSON.parse(JSON.stringify(page)), // protect against circular references
      preview: preview || null,
    },
  };
}
