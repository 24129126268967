import { Entry } from "contentful";
import { useEffect, useState } from "react";
import { contentful } from ".";

export function useComponent<T>(id: string, preview?: boolean) {
  const [component, setComponent] = useState<Entry<T>>();
  useEffect(() => {
    if (id) {
      fetchComponent();
    }
    async function fetchComponent() {
      const res = await contentful(preview).getEntry<T>(id, { include: 3 });
      setComponent(res);
    }
    // eslint is expecting T to be in the dependency array
    // but it can't because it is a type
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, preview]);

  return component;
}
