import { IBlogPost } from "components/Contentful/BlogPosts/types";
import {
  Asset,
  ContentfulClientApi,
  createClient,
  EntryCollection,
} from "contentful";
import {
  CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_PREVIEW_TOKEN,
  CONTENTFUL_SPACE,
} from "../../env";

export const contentful = (preview?: boolean): ContentfulClientApi => {
  return createClient({
    space: CONTENTFUL_SPACE,
    host: preview ? "preview.contentful.com" : "cdn.contentful.com",
    accessToken:
      preview && CONTENTFUL_PREVIEW_TOKEN
        ? CONTENTFUL_PREVIEW_TOKEN
        : CONTENTFUL_ACCESS_TOKEN,
  });
};

export const getContentfulEntries = async (
  preview = false
): Promise<EntryCollection<IBlogPost>> => {
  return await contentful(preview).getEntries<IBlogPost>({
    content_type: CONTENT_TYPES.BLOG_POST,
    limit: 4,
    skip: 0,
    order: "-fields.publishDate",
    include: 3,
    "fields.author.sys.contentType.sys.id": CONTENT_TYPES.PERSON,
  });
};

export const CONTENT_TYPES = {
  ADDRESS_FIELD: "addressField",
  BLOG_POST: "blogPost",
  BLOG_POSTS: "blogPosts",
  BUY_BUTTON: "buyButton",
  CALL_TO_ACTION: "callToAction",
  CATEGORY: "educationalVideosCategory",
  CUSTOM_CONTROL: "customControl",
  DETAILS_CARD: "detailsCard",
  DYNAMIC_SECTION: "dynamicSection",
  EDUCATIONAL_VIDEO: "educationalVideo",
  EDUCATIONAL_VIDEOS_GROUP: "educationalVideosGroup",
  EXPLAINER_BOX: "explainerBox",
  HEADING: "heading",
  HERO_BOX: "heroBox",
  ICON_TEXT: "iconText",
  IMAGE_OVERFLOW_SECTION: "imageOverflowSection",
  PAGE: "page",
  PERSON: "person",
  SECTION: "section",
  TABLE: "table",
  TESTIMONIAL: "testimonial",
  TRUSTED_BY_LOGOS: "companiesLogos",
  MENU_ITEMS: "menuItems",
  LAPTOP_PREVIEW: "laptopPreview",
  CUSTOMER_TESTIMONIALS: "customerTestimonials",
  TWO_THIRDS_COLUMNS: "twoThirdsColumns",
  THREE_COLUMNS: "threeColumns",
  FOOTER_OVERFLOW_SECTION: "footerOverflowSection",
};

export type ProfileTip = {
  title: string;
  description: string;
  image: Asset;
};

export type ContentTypeNames = "profileTip";
